define("discourse/plugins/discourse-events/discourse/helpers/event-label", ["exports", "@ember/component/helper", "discourse-common/lib/helpers", "@ember/template", "discourse/plugins/discourse-events/discourse/lib/date-utilities"], function (_exports, _helper, _helpers, _template, _dateUtilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _helper.helper)(function eventLabelHelper(_ref, args) {
    let [event] = _ref;
    let siteSettings = (0, _helpers.helperContext)().siteSettings;
    return (0, _template.htmlSafe)((0, _dateUtilities.eventLabel)(event, Object.assign({}, args, {
      siteSettings
    })));
  });
});